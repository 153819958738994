html {
    scroll-behavior: smooth;
}

a,
span,
p,
div {
    font-family: "Mulish", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Mulish", sans-serif;
    font-weight: 900;
}

body {
    font-family: 'Rajdhani', sans-serif;
    margin: 0;
    padding: 0;
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1a9dd9;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal .modal-content {
    border-radius: 20px;
    width: 600px !important;
    max-width: 600px !important;
    min-width: 600px !important;
}

.custom-modal img {
    width: 100%;
    border-radius: 20px;
}

.bottombtn {
    position: absolute;
    bottom: 10%;
    right: 10%;
    background-color: #1459a2;
    color: white;
    border-radius: 35px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: box-shadow 2s infinite;
    cursor: pointer;
    z-index: 2;
}

@keyframes box-shadow {
    0% {
        box-shadow: 0 0 0 0 rgba(70, 99, 202, 0), 0 0 0 0 rgba(70, 127, 202, 0);
    }

    100% {
        box-shadow: 0 0 0 10px rgba(202, 170, 70, 0), 0 0 0 10px rgba(202, 170, 70, 0);
    }
}

.loading {
    height: 100vh;
    width: 100%;
    background-color: #001c2d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    background: linear-gradient(90deg, rgba(7, 10, 25, .9) 30%, rgba(0, 0, 0, .5) 100%);
    padding: 10px 100px;
    position: fixed;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header img {
    width: 180px;
}

.header ul {
    display: flex;
    justify-content: center;
}

.header .menubtn {
    display: none !important;
}
.visually-hidden {
    display: none;
}
.header ul li {
    list-style: none;
    text-align: left;
    /* margin-right: 30px; */
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header ul li a {
    text-decoration: none;
    color: white;
    font-size: 17px;
    transition: .3s;
}

.header ul li a:hover {
    color: #1a9dd9;
}

.header .sub-header {
    display: none;
    width: 250px;
    background: linear-gradient(0deg, rgba(7, 10, 25, .85) 0%, rgba(7, 10, 25, .75) 50%, rgba(7, 10, 25, .65) 65%, rgba(7, 10, 25, .35) 80%, rgba(7, 10, 25, 0) 100%);
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    transition: .5s;
    position: absolute;
    top: 50px;
    z-index: 9999;
}

.header ul li:hover .sub-header {
    display: block !important;
    animation: slideDown .3s forwards;
    transition: .3s;
}

@keyframes slideDown {
    from {
        height: 0;
        margin-top: 50px;
        opacity: 0;
    }

    to {
        height: auto;
        opacity: 1;
        margin-top: 0px;
        padding: 10px 20px;
    }
}

.header ul li:hover .sub-header ul {
    display: block;
}

.header ul li:hover .sub-header ul li {
    margin-bottom: -15px;
}

.header ul li:hover .sub-header ul li {
    text-align: center;
}

.header .lang span {
    transition: .3s;
    cursor: pointer;
}

.header .lang span:hover {
    color: #1a9dd9;
}

.header .mobile-menu {
    display: none;
}

.sub-header {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, height 0.3s ease;
}

.menu-item:hover .sub-header {
    opacity: 1;
    height: auto;
}

.sub-header ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sub-header li {
    padding: 10px;
}

.main-swiper {
    height: 100vh;
    position: relative;
}

.main-swiper .text {
    position: absolute;
    bottom: 5%;
    left: 5%;
    color: white !important;
    z-index: 2;
    width: 100%;
}

.main-swiper .text h1 {
    font-size: 32px;
    font-weight: 700;
}

.main-swiper .item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-swiper .swiper-slide {
    position: relative;
}

.main-swiper .swiper-slide h2 {
    position: absolute;
    bottom: 4%;
    left: 10%;
    z-index: 2;
    font-size: 36px;
    color: white;
}

.main-swiper .swiper-button-prev,
.main-swiper .swiper-button-next {
    color: #fff;
    width: 45px;
    border-radius: 20px;
    font-size: 20px;
    opacity: .8;
}

.main-swiper .swiper-button-prev::after,
.main-swiper .swiper-button-next::after {
    font-size: 25px;
}

.main-swiper .swiper-pagination-bullet-active {
    background-color: #fff;
}

.faaliyet {
    padding: 80px;
    background: url(../images/mapalogobg.png) no-repeat right bottom;
    background-size: 50% auto;
    background-color: #FFFFFF;
}

.faaliyet .text-center h2 {
    margin-bottom: 50px;
    color: #1a9dd9;
    font-weight: 900;
    font-size: 32px;
}

.faaliyet .btn {
    background-color: #414141;
    width: 100%;
    border-radius: 20px;
    color: white;
    font-weight: 700;
    font-size: 15px;
    padding: 5px;
    cursor: pointer;
}

.faaliyet .btn:hover {
    background-color: #1a9dd9;
    transition: all 350ms ease-in-out;
}

.faaliyet .faaliyetslider {
    width: 100%;
    height: 50%;
}

.faaliyet .faaliyetslider .swiper-slide {
    text-align: center;
}

.faaliyet .faaliyetslider .swiper-slide h2 {
    margin-top: 20px;
}

.faaliyet .faaliyetslider img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.faaliyet .item {
    overflow: hidden;
    border-radius: 20px;
    background-color: white;
}

.faaliyet .item h5 {
    text-transform: capitalize;
}

.main-info {
    background: linear-gradient(20deg, #1a9dd9 20%, #1459a2 80%);
    background-size: 100% 100%;
    width: 100%;
    overflow: hidden;
    padding: 10%;
    position: relative;
    /* height: 100vh; */
    display: flex;
    align-items: center;
}

.main-info img {
    width: 64%;
    /* height: 500px; */
    object-fit: cover;
}

.main-info .show {
    background: linear-gradient(180deg, #155AA3 0%, #08223D 100%), url(../images/mapalogobg.png) no-repeat right bottom;
    background-size: 50% auto;
    padding: 15px;
    position: absolute;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    right: 10%;
    width: 440px;
}

.main-info .show img {
    display: none;
    height: 200px;
    right: -15px;
    bottom: -32px;
    z-index: 0;
    opacity: .1;
}

.main-info .show ul {
    z-index: 2;
    position: relative;
}

.main-info .show ul li {
    color: white;
    margin-bottom: 15px;
    z-index: 2;
}

.main-info .btn {
    color: #414141;
    background: #fff;
    width: 50%;
    padding: 12px 48px;
    text-align: center;
    border: 0;
    border-radius: 20px;
    font-size: 13pt;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.main-info .btn:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.main-info span {
    text-align: center;
    font-size: 16pt;
    line-height: 18pt;
    font-weight: 400;
    color: white;
}

.footer {
    animation-name: basic-show !important;
    animation-duration: 350ms;
    animation-delay: 0ms;
    animation-fill-mode: backwards;
    animation-timing-function: ease-out;
    height: 355px;
    background-image: linear-gradient(20deg, #1a9dd9 0%, #1459a2 80%);
    background-size: 100% 100%;
    padding-left: 10%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer .logo {
    width: 150px;
    height: 38px;
    background-size: contain;
}

.footer .first a {
    padding: 0;
    text-align: left;
    border: 0;
    background: none;
    font: 500 11pt "Rajdhani", Arial, Helvetica, sans-serif;
    color: white;
    text-decoration: none;
    transition: all 250ms ease-in-out;
}

.footer .title {
    position: relative;
}

.footer .title div {
    width: 50px;
    height: 2px;
    background-color: #414141;
}

.contact .text-white {
    text-shadow: -1px -1px 0 #838383, 1px -1px 0 #838383, -1px 1px 0 #838383, 1px 1px 0 #838383;
}
.footer h5 {
    font-size: 16pt;
    color: #fff;
}

.footer svg {
    color: white;
}

.footer .second a {
    padding: 0;
    text-align: left;
    border: 0;
    background: none;
    font: 500 11pt "Rajdhani", Arial, Helvetica, sans-serif;
    color: white;
    display: block;
    width: 100%;
    text-decoration: none;
    transition: all 250ms ease-in-out;
}


@media (max-width: 582px) {
    .jobs th,
    .jobs tr{
    font-size: 8pt;
    font-weight: 400;
    color: #525866;
}
.jobs {
    padding: 7% 6% !important;
}
}

@media (max-width: 768px) {
    .jobs table {
        display: table;
    }
    .jobs #jobs {
        display: none;
    }
    .jobs {
        padding: 7% 12%;;
    }
}
@media (max-width: 1129px) {
    .projects .item #country {
        height: 38px !important;
    }
}
@media (max-width: 768px) {
    .projects .item #country {
        height: auto !important;
    }
}
@media (min-width: 768px) {
    .jobs table {
        display: none;
    }
    .jobs #jobs {
        display: block;
    }
}
.jobs thead {
    background-color: #F5F7FA;
}

.jobs th {
    border: none;
    border-bottom: none !important;
}

.jobs td {
    border-top: none;
    border-bottom: 1px solid #dee2e6;
}

.footer .third a {
    padding: 0;
    text-align: left;
    border: 0;
    background: none;
    margin-left: 10px;
    font: 500 12px "Rajdhani", Arial, Helvetica, sans-serif;
    color: white;
    text-decoration: none;
    transition: all 250ms ease-in-out;
}

.btn-primary {
    width: 100%;
    border-radius: 20px;
}

@media (max-width: 768px) {
    #our-strategy #lbheimg {
        margin-top: 20px !important;
    }

}


@media (max-width: 699px) {
    .contentpage .text h1 {
        font-size: 1.5rem !important;
    }
}

@media (max-height: 698px) {
    .contact .top img {
        height: 130vh !important;
    }
}

@media (max-height: 536px) {
    .contact .top img {
        height: 160vh !important;
    }
}

@media (max-height: 439px) {
    .contact .top img {
        height: 200vh !important;
    }
}

@media (max-height: 350px) {
    .contact .top img {
        height: 230vh !important;
    }
}

@media (max-height: 302px) {
    .contact .top img {
        height: 250vh !important;
    }
}

@media (max-width: 1100px) {
    .main-swiper .swiper-slide .text {
        width: 70%;
    }

    .main-swiper .swiper-slide h1 {
        font-size: 30px;
    }

    .footer .logo {
        width: 120px;
        height: 38px;
        background-size: contain;
    }
}

@media (max-width: 767px) {
    .footer {
        height: auto !important;
    }

    .contentpage .top .text,
    .projects .top .text {
        margin-top: 80px !important;
    }
}

@media (max-width: 792px) {
    .main-info {
        height: 112vh;
        display: block !important;
    }

    .main-swiper .swiper-slide h2 {
        font-size: 28px;
    }

    .faaliyet .faaliyetslider img {
        height: 300px !important;
    }

    .main-info img {
        width: 100%;
    }

    .whitec .img-project-name {
        text-align: center !important;
    }

    #lbheimg img {
        width: 100% !important;
    }

    .bottombtn {
        bottom: 5% !important;
        right: 5% !important;
    }

    .main-info .show {
        transform: none;
        transform: translateX(-4%);
        bottom: 0;
        width: 600px;
        bottom: 100px;
    }
}
@media (max-width: 768px){
    .main-info .show {
        position: static;
        width: 100%;
        transform: none;
    }
}
@media (min-width: 768px) {
    .footer .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 100% !important;
    }
}
@media (max-width: 550px) {
    .main-info {
        height: auto;
    }

    .main-info img {
        height: 300px;
    }

    .main-swiper .swiper-slide .text {
        width: 80%;
    }

    .main-swiper .swiper-slide h1 {
        font-size: 24px;
    }
}

@media (max-width: 700px) {
    .contact .top {
        height: 179vh !important;
    }

    .contact .top img {
        height: 179vh !important;
    }

    .faaliyet {
        padding: 30px;
    }

    .faaliyet .text-center h2 {
        font-size: 30px;
    }

    .main-info .btn {
        width: 100%;
    }

    .footer {
        height: auto;
    }

    .contact .top .triangle {
        height: 109vh !important;
    }
}

@media (max-width: 1132px) {
    .header>ul {
        margin-top: 20px;
    }

    .mobile-menu img {
        width: 140px;
        filter: invert(1);
    }

    .mobile-menu2 a {
        color: black !important;
    }

    .mobile-menu2>div:first-child {
        margin-bottom: 30px !important;
    }

    .mobile-menu2 svg {
        background-color: #1459a2;
        border-radius: 20px;
        color: white;
    }
}

@media (max-width: 1092px) {
    .header ul li a {
        font-size: 14px;
    }
}

@media (max-width: 1011px) {
    .header ul li {
        margin-right: 20px;
    }
}

.sub-menu-toggle {
    display: none;
}

@media (max-width: 1259px) {
    .footer .third .adres {
        height: 89px;
    }
}

@media (max-width: 974px) {
    .footer .third .adres {
        height: 111px;
    }
    .footer {
        height: 400px;
    }
    .footer .third .telefon {
        height: 48px;
    }
}

@media (max-width: 815px) {
    .footer .third .adres {
        height: 134px;
    }
}

@media (max-width: 768px) {
    .footer .third .adres {
        height: auto !important;
    }
    .footer .third .telefon {
        height: auto !important;
    }
    .footer .third .adres {
        height: auto !important;
    }
}

@media (max-width: 951px) {

    .contact .top .info input,
    .contact .top .info textarea {
        width: 100% !important;
    }

    .contact .formelement>div:first-child {
        width: 100%;
    }

    .contact .formelement>div:last-child {
        width: 100%;
    }

    .contact .formelement>div:last-child input,
    .contact .formelement>div:last-child textarea {
        width: 100% !important;
    }

    .contact .top .info .enter label {
        position: relative;
    }
}

@media (max-width: 918px) {
    .form-control {
        padding: 8px 12px;
        font-size: 14px;
        color: #333;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        height: 40px;
    }
}

@media (max-width: 951px) {
    .header>.position-relative>.mt-2 {
        display: none;
    }

    .header .menubtn {
        display: block !important;
    }

    .header {
        padding: 20px 50px;
    }

    .header .lang {
        display: none !important;
    }

    .header .lang span {
        color: black !important;
    }

    .header .mobile-menu2 .lang {
        display: flex !important;
    }

    .header .mobile-menu {
        display: flex;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(7, 10, 25, .9);
        z-index: 999999;
    }

    .mobile-menu2 {
        background-color: #fff;
        padding: 5%;
        width: 80%;
    }

    .header .mobile-menu .space div {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-top: 10px;
        border-radius: 5px;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header .sub-header {
        display: none;
        width: 250px;
        background: white;
        padding-top: 10px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        transition: .5s;
        position: absolute;
        top: 50px;
        z-index: 9999;
    }

    .header .space {
        width: 30%;
        height: 100vh;
    }

    .header .menu img {
        filter: invert(1);
        margin-bottom: 20px;
    }

    .header .menu {
        width: 70%;
        height: 100%;
        background-color: #fff;
        padding: 30px 25px;
    }

    .header .menu ul {
        display: block !important;
        padding: 0;
        margin: 0;
    }

    .header .menu ul li {
        text-align: left;
        justify-content: flex-start;
    }

    .header .menu a {
        color: black;
    }
}


@media (max-width: 1160px) {

    .contact .top .info input,
    .contact .top .info textarea {
        width: 150px !important;
    }
}

#engagement img {
    height: auto !important;
}

#our-strategy {
    margin-top: -50px;
}

#stratejimiz {
    margin-top: -50px;
}

.contact-icon img {
    height: 20px !important;
    width: 15px !important;
}

/* .whitec.sizeimg {
     height: 441px !important; 
} */

#ungc #ungc-logo {
    max-width: 40%;
    height: auto !important;
}

#ungc img {
    height: 500px !important;
}

#istiraklerimiz img {
    height: 720px;
    object-position: bottom !important;
}

#talent-acquisition-policy img {
    height: auto !important;
}

#yetenek-kazanimi-politikasi img {
    height: auto !important;
}

#lbheimg img {
    height: auto !important;
}

#mapa-staj-programi img {
    height: auto !important;
}

.contact .img-project-name {
    bottom: 0;
    color: white;
    font-size: 12px;
    position: absolute;
    text-align: left;
    left: 0;
    margin-left: 20px;
    margin-bottom: 15px;
}

@media (max-width: 940) {
    .contact {
        margin-top: 100px;
    }
}

@media (max-width: 499px) {
    .contentpage .top .text p {
        display: -webkit-box;
        /* height: 52px; */
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 580px) {
    #enler .img-project-name {
        margin-bottom: 20px;
    }

    .contact .form-check-input {
        width: 10px !important;
    }

    .contact .top .info input,
    .contact .top .info textarea {
        width: 100% !important;
    }

    .header {
        padding: 20px 20px;
        height: 70px;
    }

    .mobile-menu2 {
        background-color: white;
        width: 80%;
        padding: 5%;
    }

    .mobile-menu2 img {
        filter: invert(1);
        margin-bottom: 30px;
    }

    .mobile-menu2 a {
        color: black !important;
    }

    .mobile-menu2 svg {
        background-color: #1459a2;
        border-radius: 20px;
        color: white;
    }

    #enler img {
        width: 100%;
        margin-bottom: 20px;
    }

    #our-affiliates img {
        height: 300px !important;
        margin-bottom: 20px;
    }

    .contentpage .top .text h1,
    .contentpage .top .text h2,
    .contentpage .top .text h3,
    .contentpage .top .text h4 {
        font-size: 27px;
    }

    .contentpage .top .text {
        width: 80% !important;
    }

    #engagement .row:nth-child(2) .col-md-6:first-child {
        order: 2;
    }

    #enler .col-md-6:first-child {
        order: 2;
    }

    #our-affiliates .col-md-6:first-child {
        order: 2;
    }

    .whitec p,
    .bluec p {
        line-height: 23px !important;
    }

    .header .mobile-menu2 .lang {
        display: flex !important;
    }

    .header .mobile-menu2 .lang span {
        color: black !important;
    }

    #our-strategy img {
        width: 100%;
    }

    .job-page #jobs {
        margin-top: 0 !important;
    }

    .job-page #jobs .row.title {
        display: none !important;
    }

    .job-page #jobs .row.title .col-md-1 {
        margin-top: 20px !important;
    }

    .custom-modal .modal-content {
        width: auto !important;
        max-width: auto !important;
        min-width: auto !important;
    }

    .whitec {
        padding: 3% !important;
    }

    .whitec div {
        padding-top: 5px;
    }

    .bluec {
        padding: 3% !important;
    }

    .bluec div {
        padding-top: 5px;
    }

    img {
        width: 100%;
    }

    .contentpage .top .text p {
        display: -webkit-box;
        /* height: 52px; */
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bottombtn {
        width: 50px;
        height: 50px;
    }

    .bottombtn svg {
        width: 25px !important;
        height: 25px !important;
    }

    .main-swiper {
        height: 55vh;
    }

    .contact .top {
        height: 175vh !important;
    }

    .contact .top .info .col-md-5 {
        margin-top: -108px;
    }

    .contact .top .info .formelement {
        display: block !important;
    }

    .loading {
        height: 100vh;
        width: 100%;
        background-color: #001c2d;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading img {
        width: 70%;
        margin-left: 5%;
    }

    .contact .top img {
        height: 175vh !important;
    }

    .contact .top .info .text-white.metin {
        padding: 0 !important;
    }

    .contact .top .info .enter {
        padding: 0 !important;
    }

    .contact .top .info .buton {
        padding: 0 !important;
    }

    .contentpage .top img,
    .job-page .top img,
    .projects .top img {
        height: 75vh !important;
    }

    .contentpage .top,
    .job-page .top,
    .projects .top {
        height: 75vh !important;
    }

    .triangle {
        height: 55vh !important;
    }
}

@media (max-width: 321px) {
    .contentpage .top {
        height: 90vh !important;
    }
    .contentpage .top img {
        height: 90vh !important;
    }
}

@media (max-width: 768px) {
    .contact .top {
        height: 183vh !important;
    }

    .contact .top img {
        height: 183vh !important;
    }
        
    #sustainability-new-img  {
        display: block !important;
        margin-bottom: 30px;
    }
    #lbheimg .col-md-6:first-child {
        display: none !important;
    }

}

@media (min-width: 768px) {
    #sustainability-new-img  {
        display: none !important;
    }
}
@media (max-width: 380px) {
    .contact .top {
        height: 200vh !important;
    }

    .contact .top img {
        height: 200vh !important;
    }
}

.contentpage .top,
.job-page .top {
    position: relative;
    display: flex;
    align-items: center;
}

.job-page .app-btn {
    height: 40px;
    border-radius: 20px;
    background-color: #0056b3;
    width: 200px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    margin-top: 30px;
}

.job-page .app-btn:hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.contact .top {
    position: relative;
    display: flex;
}

.contentpage .text p {
    font-weight: 400;
}

.project-grid h4 {
    font-size: 20px;
    display: -webkit-box;
    height: 48px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-grid button {
    border-radius: 32px;
}

.project-grid button {
    border-radius: 32px;
}

.contentpage .top.bottom {
    display: block !important;
}

.contentpage .showcase {
    overflow: hidden;
}

.contentpage .showcase a {
    text-decoration: underline;
    color: black;
}

.contentpage .showcase .ungc img {
    height: 300px !important;
    width: auto !important;
    margin-bottom: 20px;
}

.contentpage .top .text,
.projects .top .text,
.contact .top .text {
    position: absolute;
    margin-top: 120px;
    left: 10%;
    width: 40%;
    text-align: justify;
    color: white;
    font-weight: 900;
    z-index: 2;
}

.contentpage .top .text.bottom {
    bottom: 10%;
}

.contentpage .top .text h3,
.projects .top .text h3,
.contact .top .text h3 {
    font-size: 40px;
}

.job-page .top .text h3 {
    position: absolute;
    bottom: 8%;
    left: 10%;
    width: 60%;
    color: white;
    font-size: 37px;
    font-weight: 900;
    z-index: 2;
}

.faaliyet .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.contentpage .top img,
.job-page .top img,
.projects .top img,
.contact .top img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: top;
}

.contentpage .img {
    position: relative;
}

.contentpage .img img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    position: relative;
}

.contentpage .img::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: .7;
    z-index: 2;
}

.contentpage .show-case2 {
    padding: 8% 10%;
    background: url(../images/mapalogobg.png) no-repeat right bottom;
    background-size: 50% auto;
    background-color: #FFFFFF;
}

.contentpage .show-case1 {
    background: linear-gradient(20deg, #1a9dd9 20%, #1459a2 80%);
    background-size: 100% 100%;
    padding: 8% 10%;
}

.contact .top {
    display: flex;
    justify-content: center;
}

.contact .card {
    opacity: .9;
}

.contact .top .info {
    width: 70%;
}

.contact .top .info .form {
    margin-top: 100px;
}

.contact .top .info .formelement {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.contact .top .info {
    position: absolute;
    top: auto;
    left: auto;
    z-index: 2;
    display: flex;
    justify-content: center;
    margin-top: 74px;
}

.contact .top .info .kvkk {
    width: 100%;
    color: white;
    font-size: 13px;
    text-shadow: -1px -1px 0 #838383, 1px -1px 0 #838383, -1px 1px 0 #838383, 1px 1px 0 #838383
}

.contact .top .info .enter {
    display: flex;
    justify-content: start;
    color: white;
    padding-left: 20px;
    margin-top: 20px;
}

.contact .top .info .enter input {
    width: auto !important;
}

.contact .top .info .enter .btn.btn-info {
    background-color: #1B9EDA;
    color: white;
    border-radius: 32px;
    height: 52px;
}

.contentpage .top .text.bottom h1 {
    text-align: left;
}

.contact .top .info .enter span {
    text-shadow: -1px -1px 0 #838383, 1px -1px 0 #838383, -1px 1px 0 #838383, 1px 1px 0 #838383;
    font-size: 13px;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    width: 100%;
    background-color: #007bff;
    border-radius: 20px;
}

.contact .top .info input,
.contact .top .info textarea {
    background-color: rgba(255, 255, 255, 0.2);
    /* Beyaz arka plan %20 opacity ile */
    color: #FFFFFF;
    border: 0;
    width: 100% !important;
}

.contact .top .info .buton {
    padding-left: 120px;
    margin-top: 20px;
}

.contact .top .info a {
    color: white;
}

.contact-input-css {
    width: 100% !important;
}

.css-13cymwt-control {
    border-radius: 20px !important;
}

.triangle {
    animation-name: triangle-show !important;
    animation-duration: 1250ms;
    animation-delay: 100ms;
    animation-fill-mode: backwards;
    animation-timing-function: ease-out;
    position: absolute;
    top: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: url(../images/lb-z0.svg) left bottom no-repeat, url(../images/lb-z1.svg) left bottom no-repeat, url(../images/lb-z2.svg) -450px bottom no-repeat, url(../images/rt-z0.svg) calc(100% + 60px) top no-repeat, url(../images/rt-z1.svg) calc(100% + 60px) top no-repeat;
    background-blend-mode: multiply;
    background-size: 556px 493px, 383px 341px, 1300px 961px, 709px 630px, 417px 370px;
    mix-blend-mode: multiply;
}

@keyframes triangle-show {
    0% {
        opacity: 0;
        background-position: -400px bottom, -400px bottom, -850px bottom, calc(100% + 460px) top, calc(100% + 460px) top;
    }

    25% {
        opacity: 0;
    }

    50% {
        background-position: -100px bottom, -100px bottom, -500px bottom, calc(100% + 160px) top, calc(100% + 160px) top;
    }
}

.application-page .application {
    padding: 7% 10%;
    background-size: 50% auto;
    background-color: #F4F4F4;
    background: url(../images/mapalogobg.png) no-repeat right bottom;
}

.application-form {
    margin-top: 70px;
    padding: 20px;
    background-color: #F9F9F9;
    border-radius: 8px;
    padding: 52px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.application-form .information {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #525866;
}

.application-form .information img {
    margin-right: 4px;
    height: 16px;
    width: 16px;
}

.application-form h1 {
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    margin-bottom: 20px;
}

.application-form h2 {
    font-weight: 600;
    font-size: 34px;
    margin-top: 36px;
    margin-bottom: 10px;
}

.form-row {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.form-group .form-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-top: 20px;
}

.file-input-wrapper {
    position: relative;
    display: inline-block;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    width: 300px;
    height: 40px;
}

.file-icon {
    margin-right: 8px;
    width: 19px;
    height: 18px;
}

.required {
    color: #007bff;
}

.form-control {
    padding: 8px 12px;
    font-size: 14px;
    color: #525866;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    width: 300px;
    height: 40px;

}

.form-group #reading,
.form-group #writing,
.form-group #speaking {
    width: 206px;
}

.application-button {
    margin-top: 82px;
    width: 416px;
    background-color: #1B9EDA;
    color: #FFFFFF;
    border: none;
    padding: 14px;
    border-radius: 32px;
}

.phone-group {
    display: flex;
    flex-direction: column;
}

.phone-input {
    display: flex;
    align-items: center;
}

select {
    appearance: none;
    /* Varsayılan ok işaretini kaldır */
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/arrow-icon.png') no-repeat right center;
    background-position: calc(100% - 10px) center;
    /* Oku biraz daha sola çek */
    padding-right: 10px;
    /* Ok simgesine yer aç */
}

.phone-code-select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px 0 0 8px;
    background-color: #fff;
    width: 82px;
}

.phone-number-input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 0 8px 8px 0;
    flex: 1;
}

.job-page .jobs {
    padding: 7% 15%;
    background: url(../images/mapalogobg.png) no-repeat right bottom;
    background-size: 50% auto;
    background-color: #FFFFFF;
}

.job-page .jobs h2 {
    color: #1a9dd9;
    font-size: 24pt;
    padding-bottom: 16px;
}

.job-page #jobs {
    font-size: 16pt;
    margin-top: 30px;
}

.job-page #jobs .row {
    padding: 24px 0;
    border-bottom: 1px solid #ccc;
}

.job-page #jobs .row.title {
    color: #1a9dd9;
    font-weight: 700;
    padding: 24px 0;
    border-bottom: 1px solid #ccc;
}

.job-page #jobs .row .col:nth-child(1) {
    font-weight: 700;
}

.job-page #jobs .row a {
    display: block;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: url(../images/career-arrow-blue.svg) center no-repeat;
    background-size: contain;
    opacity: .6;
    transition: all 250ms ease-in-out;
    color: #1a9dd9;
}

.job-page #jobs .row a:hover {
    transform: rotate(-45deg);
}

#mapa-staj-programi a,
#mapa-internship-program a {
    border-radius: 32px;
    color: white;
    text-decoration: none;
    width: 100px;
}

#mapa-staj-programi ul,
#mapa-internship-program ul {
    margin-left: -30px;
}

#mapa-staj-programi ul li,
#mapa-internship-program ul li {
    margin-bottom: 10px;
    list-style-type: none;
    padding-left: 2em;
    background-image: url('../images/20930-list-image.png');
    background-size: 1.5em;
    background-repeat: no-repeat;
}

#politikalarimiz-ve-standartlarimiz li,
#our-policies-and-standards li {
    margin-bottom: 10px;
    list-style-type: none;
    padding-left: 1.5em;
    background-image: url('../images/stand-link.png');
    background-size: 1.2em;
    background-repeat: no-repeat;
}

#politikalarimiz-ve-standartlarimiz li a,
#our-policies-and-standards li a {
    color: #009FE3;
}

/* .project-details div {
    font-size: ;
} */

/* .project-details .row .col-md-12 .mt-3 {
    display: flex;
    text-align: left;
    justify-content: space-between;
    text-align: left;
}

.project-details span {
    text-align: right;
    right: 0;
    width: 50%;
    word-wrap: break-word;
} */
.first a {
    text-transform: capitalize;
}

.projects .project-grid {
    padding: 6% 10%;
    background: url(../images/mapalogobg.png) no-repeat right bottom;
    background-size: 50% auto;
    background-color: #FFFFFF;
}

#job-component {
    margin-top: -50px;
}

.projects .project-grid .item {
    overflow: hidden;
    border: 1px solid #ededed;
    border-radius: 10px;
    cursor: pointer;
    transition: .4s;
}

.projects .project-grid .item:hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.projects .project-grid .item img {
    height: 174px;
    max-height: 330px;
    object-fit: cover;
    width: 100%;
}

.projects .popup img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.btn-close {
    transform: translate(8px, -13px);
    padding: 0.8rem;
    opacity: 1;
    transition: all .23s ease .1s;
    position: relative;
    margin: -0.4rem -0.7rem -0.4rem auto;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/.75rem auto no-repeat;
    border: 0;
    border-radius: 0.357rem;
    color: currentColor;
    box-sizing: content-box;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.read-more {
    cursor: pointer;
}

.footer .social img {
    /* width: 35px;
    height: 35px; */
    object-fit: cover;
}

.footer .first .mt-4 {
    transition: .4s;
}

.footer .first .mt-4:hover {
    margin-left: 5px;
}

.footer .social .mr-3 {
    transition: .4s;
}

.footer .third .mb-3 {
    transition: .4s;
}

.footer .third .mb-3:hover {
    margin-left: 5px;
}

.footer .social .mr-3:hover {
    margin-top: 5px !important;
}

.footer .second .mb-2 {
    transition: .4s;
}

.footer .second .mb-2:hover {
    margin-left: 5px;
}

.whitec {
    padding: 10%;
    background: url(../images/mapalogobg.png) no-repeat right bottom;
    background-size: 50% auto;
    background-color: #FFFFFF;
}

.whitec img {
    height: 600px;
    width: 100%;
    object-fit: cover;
}

.whitec h1,
.whitec h2,
.whitec h4,
.whitec h5,
.whitec h3 {
    margin-bottom: 30px;
}

.whitec p {
    line-height: 30px;
}

.whitec .col-md-6 {
    display: flex;
    align-items: center;
}

.whitec div {
    width: 100%;
    margin: 0;
}

.bluec {
    padding: 10%;
    background: linear-gradient(20deg, #1a9dd9 20%, #1459a2 80%);
    background-size: 100% 100%;
    color: white;
}

.bluec img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: left;
}

.bluec h1,
.bluec h2,
.bluec h3,
.bluec h4,
.bluec h5 {
    margin-bottom: 30px;
}

#lbheimg img {
    width: 90%;
}

.fade-in-right {
    opacity: 0;
    transform: translateX(100%);
    animation: fadeInRight 1s forwards;
}

@keyframes fadeInRight {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

#our-affiliates img {
    height: 720px;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.whitec strong {
    color: black;
}

.bluec p {
    line-height: 30px;
}

.bluec .col-md-6 {
    display: flex;
    align-items: center;
}

.bluec div {
    width: 100%;
    margin: 0;
}

.projects .top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
}

.projects .top .text {
    bottom: 10%;
}

.projects .top .text h1 span {
    color: white !important;
}


.custom-slide {
    position: relative;
}

.custom-slide img {
    width: 100%;
    height: auto;
    display: block;
}

.mobile-menu2 {
    overflow: auto;
}

.custom-slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
    pointer-events: none;
    z-index: 1;
}

@media (max-width: 1024px) {
    .phone-number-input {
        width: 218px;
    }

    .form-group #reading,
    .form-group #writing,
    .form-group #speaking {
        width: 300px;
    }
}

@media (max-width: 919px) {
    .form-row {
        row-gap: 0px;
    }
}

@media (max-width: 506px) {
    .form-control {
        width: 308px !important;
    }

    .phone-number-input {
        width: 251px !important;

    }

    .application-form {
        padding: 16px;
    }

    .form-group #reading,
    .form-group #writing,
    .form-group #speaking {
        width: 308px !important;
    }

    .custom-file-upload {

        width: 308px !important;
    }

    .phone-code-select {
        width: 57px !important;
        font-size: 10px;
    }

    select {
        background-position: calc(100% - 5px) center;
        /* Oku biraz daha sola çek */
    }

}

@media (max-width: 426px) {


    .application-form {
        padding: 16px;
    }

    .application-form h1 {
        font-weight: 600;
        font-size: 29px;
        text-align: center;
        margin-bottom: 20px;
    }

    .application-form h2 {
        font-weight: 600;
        font-size: 21px;
        margin-top: 36px;
        margin-bottom: 10px;
    }

    .form-control {
        width: 239px !important;
    }

    .phone-number-input {
        width: 182px !important;

    }

    .form-group #reading,
    .form-group #writing,
    .form-group #speaking {
        width: 239px !important;
    }

    .custom-file-upload {

        width: 239px !important;
    }

    .phone-code-select {
        width: 57px !important;
        font-size: 10px !important;
    }

    select {
        background-position: calc(100% - 5px) center;
        /* Oku biraz daha sola çek */
    }

}

@media (max-width: 376px) {


    .application-form h1 {
        font-weight: 600;
        font-size: 29px;
        text-align: center;
        margin-bottom: 20px;
    }

    .application-form h2 {
        font-weight: 600;
        font-size: 21px;
        margin-top: 36px;
        margin-bottom: 10px;
    }

    .form-control {
        width: 208px !important;
        ;
    }

    .phone-number-input {
        width: 151px !important;

    }

    .form-group #reading,
    .form-group #writing,
    .form-group #speaking {
        width: 208px !important;
    }

    .custom-file-upload {

        width: 208px !important;
    }

    .phone-code-select {
        width: 57px !important;
        font-size: 10px !important;
    }

    select {
        background-position: calc(100% - 5px) center;
        /* Oku biraz daha sola çek */
    }

}

@media (max-width: 321px) {


    .application-form h1 {
        font-weight: 600;
        font-size: 29px;
        text-align: center;
        margin-bottom: 20px;
    }

    .application-form h2 {
        font-weight: 600;
        font-size: 21px;
        margin-top: 36px;
        margin-bottom: 10px;
    }

    .form-control {
        width: 169px !important
    }

    .phone-number-input {
        width: 112px !important;

    }

    .form-group #reading,
    .form-group #writing,
    .form-group #speaking {
        width: 169px !important
    }

    .custom-file-upload {

        width: 169px !important
    }

    .phone-code-select {
        width: 57px !important;
        font-size: 10px !important;
    }

    select {
        background-position: calc(100% - 5px) center;
        /* Oku biraz daha sola çek */
    }

}

@media (max-height: 566px) {
    .contentpage .top img {
        width: 100%;
        height: 150vh;
        object-fit: cover;
        object-position: top;
    }
}

@media (max-height: 420px) {
    .contentpage .top img {
        width: 100%;
        height: 170vh;
        object-fit: cover;
        object-position: top;
    }
}

/* Arkaplan ve form genel stil */
.application-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.submit-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

.whitec .img-project-name {
    position: absolute;
    z-index: 1;
    bottom: 0;
    color: white;
    text-transform: capitalize !important;
    font-size: 12px;
    text-align: right;
    right: 0;
    text-shadow: 0px 2px 5px #000;
    padding: 6px;
    border-start-start-radius: 10px;
}

.whitec img {
    position: relative;
}

.bluec .img-project-name {
    position: absolute;
    z-index: 1;
    bottom: 0;
    color: white;
    font-size: 12px;
    text-transform: capitalize !important;
    text-align: left !important;
    left: 0;
    text-shadow: 0px 2px 5px #000;
    padding: 6px;
    border-start-start-radius: 10px;
}

.whitec img {
    position: relative;
}

/* Popup stil */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.popup {
    position: relative;
    width: 800px;
    height: 455px;
    padding: 20px;
    background-color: #fff;
    border-radius: 24px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.check-icon {
    color: #1DAF61;
    margin-bottom: 15px;
    height: 74px;
    width: 74px;
}

.popup-content h2 {
    color: #1DAF61;
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 14px;
}

.popup-content p {
    margin-top: 8px;
    font-size: 20px;
    color: #666;
}

.close-button img {
    width: 18px;
    height: 18px;
}

.error {
    color: rgb(210, 58, 58);
}

/* Kapatma butonu */
.close-button {
    position: absolute;
    top: 50px;
    height: 42px;
    width: 42px;
    right: 48px;
    font-size: 24px;
    border: none;
    background-color: #EFEFEF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button:hover {
    color: #000;
}
